/* eslint-disable lines-between-class-members */
import { TaskPriorityEnum, TaskTypesEnum, TaskStatusEnum } from '@/enums'
import BaseSearchFilter from './BaseSearchFilter'

export class TaskFilter extends BaseSearchFilter {
  /** @type {string} */
  #taskType = ''
  #taskStatuses = []
  #priority = ''
  #assignTo = ''
  constructor() {
    super()
    this.#taskType = this.taskType
    this.#taskStatuses = this.taskStatuses
    this.#priority = this.priority
    this.#assignTo = this.assignTo
  }

  setDefaultFilters() {
    this.accountNo = ''
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.q = ''
    this.taskType = TaskTypesEnum.ALL.value
    this.taskStatus = TaskStatusEnum.ALL.value
    this.priority = TaskPriorityEnum.ALL.value
    this.assignTo = ''
  }

  /** @returns {Object} filter object */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      q: this.q,
      taskType: this.taskType,
      taskStatuses: this.taskStatuses,
      priority: this.priority,
      assignTo: this.assignTo,
    }
    return filter
  }

  /** @returns {string} */
  get taskType() {
    return this.#taskType
  }
  /** @param {string} value */
  set taskType(value) {
    this.#taskType = value
  }
  /** @returns {string} */
  get taskStatuses() {
    return this.#taskStatuses
  }
  /** @param {string} value */
  set taskStatuses(value) {
    this.#taskStatuses = value
  }
  /** @returns {string} */
  get priority() {
    return this.#priority
  }
  /** @param {string} value */
  set priority(value) {
    this.#priority = value
  }
  /** @returns {string} */
  get assignTo() {
    return this.#assignTo
  }
  /** @param {string} value */
  set assignTo(value) {
    this.#assignTo = value
  }
}
export default new TaskFilter()
