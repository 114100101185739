<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <dx-data-grid
          id="tasksGrid"
          ref="tasksGridRef"
          height="calc(100vh - 150px)"
          :data-source="taskDataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @row-removed="searchByFilter"
          @option-changed="onOptionChanged"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing($event)"
          @editor-preparing="onEditorPreparing"
        >
          <!--region    ::DataGrid base components -->
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-column-chooser v-if="hasTaskSupervision" :enabled="true" mode="select" title="Column Chooser" />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-editing
            mode="row"
            :allow-updating="true"
            :allow-deleting="true"
          />
          <dx-column-fixing :enabled="true" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <!--endregion ::DataGrid base components -->
          <dx-column data-field="taskType" width="120" cell-template="taskTypeTemplate">
            <dx-lookup
              :data-source="prepareOptions(taskTypes)"
              display-expr="text"
              value-expr="value"
            />
          </dx-column>
          <template #taskTypeTemplate="{ data }">
            <div :class="resolveType(data.value)" class="badge d-block">
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="subject" />
          <dx-column data-field="assignToFullName" caption="Owner" width="160" cell-template="ownerTemplate">
            <dx-lookup
              :data-source="currentCompanyUsers"
              display-expr="fullName"
              value-expr="fullName"
            />
          </dx-column>
          <template #ownerTemplate="{data}">
            <div>
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="accountNo" caption="Acc #" width="70" />
          <dx-column data-field="companyName" :visible="false" />
          <dx-column data-field="storeName" :visible="false" />
          <dx-column data-field="estimateDuration" caption="Estimate" width="120" />
          <dx-column data-field="startTime" width="140" caption="Working Date" data-type="date" :format="'yyyy-MM-dd HH:mm'" />
          <dx-column v-if="hasTaskSupervision" data-field="assignTime" width="140" caption="Assigned At" data-type="date" :format="'yyyy-MM-dd HH:mm'" />
          <dx-column data-field="actualStartTime" caption="Started At" width="140" data-type="date" :format="'yyyy-MM-dd HH:mm'" />
          <dx-column v-if="hasTaskSupervision" data-field="finishTime" width="140" caption="Finished At" data-type="date" :format="'yyyy-MM-dd HH:mm'" />
          <dx-column v-if="hasTaskSupervision" data-field="assignerFullName" caption="Assigned By" width="130" />
          <dx-column data-field="count" width="60" />
          <dx-column data-field="notes" />
          <dx-column data-field="priority" width="100" cell-template="priorityTemplate">
            <dx-lookup
              :data-source="prepareOptions(taskPriorities)"
              display-expr="text"
              value-expr="value"
            />
          </dx-column>
          <template #priorityTemplate="{ data }">
            <div :class="resolvePriority(data.value)" class="badge d-block">
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="taskStatus" caption="Status" width="120" cell-template="statusTemplate">
            <dx-lookup
              :data-source="prepareOptions(taskStatus)"
              display-expr="text"
              value-expr="value"
            />
          </dx-column>
          <template #statusTemplate="{ data }">
            <div :class="resolveStatus(data.value)" class="badge d-block">
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="hasTaskSupervision ? 170 : 120" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div class="d-flex">
              <div class="ml-half">
                <dx-util-button v-if="data.data.taskStatus === 'OPEN'" text="Start" type="default" width="100" @click="updateTask(data.data.id, 'WORKING')" />
                <dx-util-button v-if="data.data.taskStatus === 'WORKING'" text="Complete" width="100" type="warning" @click="updateTask(data.data.id, 'COMPLETED')" />
                <dx-util-button v-if="data.data.taskStatus === 'COMPLETED'" text="DONE" width="100" :disabled="true" />
                <dx-util-button v-if="(userRoleName === 'WOWNER' || userRoleName === 'SUPERUSER') &&
                  data.data.taskStatus === 'ON_HOLD'"
                  hint="Make the task ready" width="100"
                  text="Open" type="success"
                  @click="updateTask(data.data.id, 'OPEN')"
                />
              </div>
              <div class="pl-half" style="width: 40px;">
                <dx-util-button v-if="hasTaskSupervision && !(data.data.taskStatus === 'ON_HOLD' ||
                  data.data.taskStatus === 'COMPLETED')" hint="Make the task on-hold or deferred"
                  icon="icon bi-stop-circle" type="danger"
                  @click="updateTask(data.data.id, 'ON_HOLD')"
                />
              </div>
            </div>
          </template>
          <!-- v-if does not work for datagrid buttons -->
          <dx-column :visible="hasTaskSupervision" type="buttons" caption="Management" width="120" />
          <template #filterToolbar>
            <div class="d-flex">
              <div v-if="hasPermission" class="mr-1">
                <dx-util-text-box
                  v-model.trim="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  width="160px"
                  placeholder="Account or Suite No"
                  @key-down="onFilterByAccountNo"
                  @value-changed="onFilterByAccountNo"
                />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  v-model="selectedStore"
                  :data-source="stores"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  v-model="filter.taskType"
                  :data-source="taskTypes"
                  display-expr="text" value-expr="value"
                  placeholder="Select task type"
                  @selection-changed="searchByFilter"
                />
              </div>
              <dx-util-tag-box
                v-model="filter.taskStatuses"
                placeholder="Select Statuses"
                :data-source="taskStatus"
                display-expr="text" value-expr="value"
                class="mx-half" style="min-width: 300px;"
                :show-selection-controls="true"
                :max-displayed-tags="3"
                @selection-changed="searchByFilter"
              />
              <div class="mr-1">
                <dx-util-select-box
                  v-model="filter.priority"
                  :data-source="taskPriorities"
                  display-expr="text" value-expr="value"
                  placeholder="Select task priority"
                  @selection-changed="searchByFilter"
                />
              </div>
              <div class="mr-1">
                <date-selection default-date="last7Days" @setdates="onDatesSelection" />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  v-model="filter.assignTo"
                  placeholder="Select User"
                  :data-source="currentCompanyUsers"
                  :search-enabled="true"
                  display-expr="fullName"
                  value-expr="id"
                  @value-changed="searchByFilter"
                />
              </div>
              <div v-if="false" class="mr-1">
                <dx-util-check-box :value="onlyAssignedMe" text="Only assigned me" class="p-custom" @value-changed="showOnlyAssignedMe" />
              </div>
            </div>
          </template>
        </dx-data-grid>
      </div>
      <add-task :component-id="addTaskCompId" @emit-form-saved="searchByFilter" />
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
//  import companyService from '@/http/requests/company/companyService'
import TaskSearchFilter from '@/http/models/search-filters/TaskSearchFilter'
import DateSelection from '@/views/dashboard/statistics/components/DateSelection.vue'
import { getTaskTypesEnumList } from '@/enums/taskType.enum'
import { getStatusEnumList } from '@/enums/taskStatus.enum'
import { TaskPriorityEnum, TaskStatusEnum, TaskTypesEnum } from '@/enums'
import { getPriorityEnumList } from '@/enums/taskPriority.enum'
import Filters from '@robustshell/mixins/filters'
import { v4 as uuidv4 } from 'uuid'
// import companyService from '@/http/requests/company/companyService'
import userManagementService from '@/http/requests/system/userManagementService'
import UserSearchFilter from '@/http/models/search-filters/UserSearchFilter'
import Pager from '@/@core/dev-extreme/utils/pager'
import useCurrentUser from '@/libs/app/current-user'
import companyService from '@/http/requests/company/companyService'
import { taskDataSource } from './taskManagementDataSource'
import AddTask from './components/AddTask.vue'

export default {
  components: {
    'date-selection': DateSelection,
    'add-task': AddTask,
  },
  mixins: [GridBase, Filters],
  data() {
    return {
      taskDataSource,
      taskTypes: getTaskTypesEnumList(),
      taskStatus: getStatusEnumList(),
      taskPriorities: getPriorityEnumList(),
      currentCompanyUsers: [],
      addTaskCompId: '',
      filter: {
        beginDate: '',
        endDate: '',
        taskType: TaskTypesEnum.ALL.value,
        taskStatuses: [TaskStatusEnum.OPEN.value, TaskStatusEnum.WORKING.value],
        priority: TaskPriorityEnum.ALL.value,
        query: '',
        assignTo: '',
      },
      onlyAssignedMe: false,
    }
  },
  setup() {
    const { userRoleName, userId, userCompanyId } = useCurrentUser()
    return {
      userRoleName, userId, userCompanyId,
    }
  },
  computed: {
    dataGrid() {
      const grid = this.$refs.tasksGridRef.instance
      return grid
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    hasTaskSupervision() {
      return this.$can('read', 'resource_task_supervision')
    },
  },
  created() {
    this.getStores()
    this.loadData()
    this.getUsersList(this.userCompanyId)
  },
  methods: {
    resolveStatus(status) {
      if (status === TaskStatusEnum.COMPLETED.value) return 'badge-light-success'
      if (status === TaskStatusEnum.WORKING.value) return 'badge-light-warning'
      if (status === TaskStatusEnum.ON_HOLD.value) return 'badge-light-danger'
      if (status === TaskStatusEnum.OPEN.value) return 'badge-light-primary'
      return 'badge-primary'
    },
    resolvePriority(priority) {
      if (priority === TaskPriorityEnum.HIGH.value) return 'badge-light-danger'
      if (priority === TaskPriorityEnum.MEDIUM.value) return 'badge-light-warning'
      if (priority === TaskPriorityEnum.LOW.value) return 'badge-light-primary'
      return 'badge-primary'
    },
    resolveType(type) {
      if (type === TaskTypesEnum.PROBLEM.value) return 'badge-light-danger'
      if (type === TaskTypesEnum.RECEIVE_ITEM) return 'badge-light-success'
      if (type === TaskTypesEnum.SHIPMENT) return 'badge-light-warning'
      if (type === TaskTypesEnum.SORTING.value) return 'badge-light-info'
      if (type === TaskTypesEnum.OTHER.value) return 'badge-light-secondary'
      return 'badge-light-primary'
    },
    getUsersList(companyId) {
      UserSearchFilter.setDefaultFilters()
      UserSearchFilter.companyId = companyId
      UserSearchFilter.activated = true
      const filters = UserSearchFilter.getFilters()
      const pager = new Pager()
      userManagementService
        .getUserInfoBasicByQuery(filters, pager.staticPageable)
        .then(response => {
          const data = response.data
          this.currentCompanyUsers = data.content
        })
    },
    prepareOptions(enumList) {
      return enumList.filter(option => option.value !== 'ALL')
    },
    onDatesSelection(e) {
      this.filter.beginDate = e.beginDate
      this.filter.endDate = e.endDate
      this.searchByFilter()
    },
    async onPrioritySelectChange(cellData) {
      cellData.setValue(cellData.value)
      cellData.component.focus()
    },
    async updateTask(taskId, status) {
      await companyService.updateTask({ id: taskId, taskStatus: status })
      this.searchByFilter()
    },
    loadData() {
      TaskSearchFilter.setDefaultFilters()
      const taskFilter = TaskSearchFilter.getFilters()
      taskDataSource.searchValue(taskFilter)
    },
    searchByFilter() {
      TaskSearchFilter.accountNo = this.accountNo
      TaskSearchFilter.storeId = this.selectedStore
      TaskSearchFilter.q = this.filter.query
      TaskSearchFilter.assignTo = this.filter.assignTo
      TaskSearchFilter.beginDate = this.filter.beginDate
      TaskSearchFilter.endDate = this.filter.endDate
      TaskSearchFilter.priority = this.filter.priority
      TaskSearchFilter.taskType = this.filter.taskType
      TaskSearchFilter.taskStatuses = this.filter.taskStatuses
      const taskFilter = TaskSearchFilter.getFilters()
      taskDataSource.searchValue(taskFilter)
      taskDataSource.reload()
    },
    showOnlyAssignedMe(e) {
      if (e.value) {
        this.filter.assignTo = this.userId
      } else {
        this.filter.assignTo = ''
      }
      this.searchByFilter()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value.toString()
          this.getStores(this.accountNo)
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.selectedStore = 0
        this.searchByFilter()
      }
    },
    onOptionChanged(e) {
      if (e.fullName === 'searchPanel.text') {
        this.filter.query = ''
        this.filter.query = e.value
      }
    },
    onEditorPreparing(e) {
      if (e.parentType !== 'searchPanel') return
      e.editorOptions.onValueChanged = arg => {
        const query = arg.component.option('value')
        if (!query) {
          this.filter.query = query.trim()
          e.component.searchByText('')
          this.searchByFilter()
        }
      }
      e.editorOptions.onKeyDown = arg => {
        if (arg.event.keyCode === 13) {
          let query = arg.component.option('value')
          query = query.trim()
          arg.component.option('value', query)
          this.filter.query = query
          this.searchByFilter()
          e.component.searchByText(query)
        }
        if (arg.event.keyCode === 27) {
          e.component.searchByText('')
          arg.component.option('value', '')
          this.filter.query = ''
          this.searchByFilter()
        }
      }
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Task Calendar',
          type: 'success',
          onClick: () => {
            this.$router.push({ name: 'route-task-calendar' })
          },
        },
        location: 'after',
      })
      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        visible: this.hasTaskSupervision,
        options: {
          icon: 'add',
          onClick: () => {
            this.addTaskCompId = uuidv4()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            taskDataSource.reload()
          },
        },
        location: 'after',
      })
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
