<template>
  <dx-util-popup
    ref="taskPopupRef"
    height="auto"
    width="800px"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="taskPopupShown"
    @hidden="taskPopupHidden"
  >
    <div>
      <dx-util-validation-group :ref="taskFormRef">
        <div class="form-grid">
          <div class="span-two d-flex align-items-center mb-1">
            <b-avatar
              size="40"
              :src="selectedUser.avatar"
              variant="light-primary"
              badge
              class="badge-minimal"
              badge-variant="success"
            >
              <feather-icon
                v-if="!selectedUser.avatar"
                icon="UserIcon"
                size="22"
              />
            </b-avatar>
            <span class="text-warning ml-2 font-weight-bold">
              {{ selectedUser.fullName }}
            </span>
          </div>
          <div>
            <label for="assignedTo">Owner</label>
            <dx-util-select-box
              id="assignedTo"
              v-model="taskForm.assignTo"
              :data-source="currentCompanyUsers"
              :display-expr="customDisplayExpr" value-expr="id"
              placeholder="Select staff"
              @selection-changed="onStaffChanged"
            >
              <dx-util-validator>
                <dx-util-required-rule message="Task should assign to a person" />
              </dx-util-validator>
            </dx-util-select-box>
          </div>
          <div>
            <label for="taskStatus">Task Status</label>
            <dx-util-select-box
              id="taskStatus"
              v-model="taskForm.taskStatus"
              :data-source="taskStatus"
              display-expr="text" value-expr="value"
              placeholder="Select task status"
            />
          </div>
          <div>
            <label for="taskPriority">Priority</label>
            <dx-util-select-box
              id="taskPriority"
              v-model="taskForm.priority"
              :data-source="taskPriorities"
              display-expr="text" value-expr="value"
              placeholder="Select priority"
            />
          </div>
          <div>
            <label for="taskType">Task Type</label>
            <dx-util-select-box
              id="taskType"
              v-model="taskForm.taskType"
              :data-source="taskTypes"
              display-expr="text" value-expr="value"
              placeholder="Select task type"
              @selection-changed="updateTaskSubject"
            />
          </div>
          <div class="d-flex span-two mt-1">
            <div v-for="day in weekDays" :key="day"
              class="p-half border w-100 text-center" role="button"
              :class="{'bg-success text-white' : day === workingDay}"
              @click="updateWorkingDayList(day)"
            >
              {{ day }}
            </div>
          </div>
          <div class="d-flex span-two align-items-end py-half">
            <dx-util-button v-if="!showSearch" icon="search" type="default" class="mr-half" @click="showSearchBox" />
            <dx-util-button v-if="showSearch" icon="icon bi-list" class="mr-half" @click="showListBox" />
            <div v-show="!showSearch" class="w-50 mr-1">
              <label for="companies">Companies</label>
              <dx-util-select-box
                id="companies"
                v-model="taskForm.companyId"
                :data-source="companyList"
                display-expr="text"
                value-expr="value"
                :show-clear-button="true"
                placeholder="Select a company"
                @selection-changed="getStoresByParentCompanyId"
              />
            </div>
            <div v-show="showSearch" class="w-50 mr-1">
              <label for="searchCompanies">Search Companies</label>
              <dx-util-select-box
                id="searchCompanies"
                ref="searchCompanies"
                :data-source="customerCompanies"
                :min-search-length="3"
                :max-item-count="10"
                :show-clear-button="true"
                :search-enabled="true"
                display-expr="companyNameWithAccount"
                value-expr="companyId"
                :search-expr="['accountNo', 'name']"
                :defer-rendering="true"
                :focus-state-enabled="false"
                search-mode="contains"
                placeholder="Company Name / Account No"
                @input="debouncedSearchCompany"
                @selection-changed="getStoresByParentCompanyId"
              />
            </div>
            <div class="mr-1 w-50">
            <label for="store">Store</label>
            <dx-util-select-box
              id="store"
              v-model="taskForm.storeId"
              :data-source="stores"
              :search-enabled="true"
              display-expr="text"
              value-expr="value"
            />
          </div>
        </div>
            </div>
            <div class="span-two">
              <label for="subject">Subject</label>
              <dx-util-text-box id="subject" v-model="taskForm.subject"
                :show-clear-button="true"
                mode="text" placeholder="Task description"
              >
                <dx-util-validator>
                  <dx-util-required-rule message="Task description" />
                </dx-util-validator>
              </dx-util-text-box>
            </div>
            <div class="span-two">
              <label for="notes">Notes</label>
              <dx-util-text-box id="notes" v-model="taskForm.notes"
                :show-clear-button="true"
                mode="text" placeholder="Task details"
              />
            </div>
            <div class="d-flex">
              <div class="mr-1">
                <label for="startTime">Working Day</label>
                <dx-util-date-box
                  id="startTime"
                  v-model="taskForm.startTime"
                  placeholder="Working Date"
                  type="date"
                />
              </div>
              <div class="mr-1">
                <label for="count">Count</label>
                <dx-util-number-box
                  id="count"
                  v-model="taskForm.count"
                  placeholder="Enter item count"
                />
              </div>
              <div class="mr-1">
                <label for="timeHour">Estimate Time (Hour)</label>
                <dx-util-number-box
                  id="timeHour"
                  v-model="taskForm.estimateValue.hour"
                  :min="0"
                  :max="23"
                  placeholder="Enter duration for task in hour"
                />
              </div>
              <div>
                <label for="timeMinute">Estimate Time (Minute)</label>
                <dx-util-number-box
                  id="timeMinute"
                  v-model="taskForm.estimateValue.minute"
                  :min="0"
                  :max="59"
                  placeholder="Enter duration for task in hour"
                />
              </div>
            </div>
          <div>
        </div>
      </dx-util-validation-group>
    </div>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Save" type="success" style="width:100px" @click="handleSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
import companyService from '@/http/requests/company/companyService'
import { getTaskTypesEnumList } from '@/enums/taskType.enum'
import { getStatusEnumList } from '@/enums/taskStatus.enum'
import { TaskPriorityEnum, TaskStatusEnum, TaskTypesEnum } from '@/enums'
import { getPriorityEnumList } from '@/enums/taskPriority.enum'
import UserSearchFilter from '@/http/models/search-filters/UserSearchFilter'
import Pager from '@/@core/dev-extreme/utils/pager'
import userManagementService from '@/http/requests/system/userManagementService'
import useCurrentUser from '@/libs/app/current-user'
import commonEntityService from '@/http/requests/common/commonEntityService'
import { useDebounceFn } from '@vueuse/core'
import { Notify } from '@/@robustshell/utils'

// import { Notify } from '@/@robustshell/utils'
const today = new Date()

const initialTaskForm = {
  assignTo: null,
  taskType: TaskTypesEnum.SHIPMENT.value,
  subject: TaskTypesEnum.SHIPMENT.text,
  taskStatus: TaskStatusEnum.OPEN.value,
  priority: TaskPriorityEnum.MEDIUM.value,
  assignTime: today,
  companyId: null,
  storeId: null,
  startTime: today,
  actualStartTime: null,
  finishTime: null,
  count: 0,
  notes: '',
  estimateValue: {
    hour: 0,
    minute: 0,
  },
}
export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      weekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      workingDay: '',
      taskFormRef: 'taskFormRef',
      popupTitle: '',
      taskForm: { ...initialTaskForm },
      taskTypes: this.prepareOptions(getTaskTypesEnumList()),
      taskStatus: this.prepareOptions(getStatusEnumList()),
      taskPriorities: this.prepareOptions(getPriorityEnumList()),
      currentCompanyUsers: [],
      selectedUser: {},
      stores: [],
      companyList: [],
      customerCompanies: [],
      showSearch: false,
      debouncedSearchCompany: null,
    }
  },
  computed: {
    taskPopup() {
      return this.$refs.taskPopupRef.instance
    },
    quantityOptions() {
      return {
        format: '#,##0',
        min: 0,
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.taskPopup.show()
        this.getUsersList()
      },
    },
  },
  created() {
    this.debouncedSearchCompany = useDebounceFn(this.searchCompany, 1000)
  },
  mounted() {
    this.workingDay = this.weekDays[today.getDay() - 1]
    this.getWorkingDayCompanies()
  },
  setup() {
    const { userCompanyId, userId } = useCurrentUser()
    return {
      userCompanyId, userId,
    }
  },
  methods: {
    taskPopupShown() {
      this.popupTitle = 'New Task'
    },
    prepareOptions(enumList) {
      return enumList.filter(option => option.value !== 'ALL')
    },
    showSearchBox() {
      this.showSearch = true
      this.taskForm.companyId = null
    },
    showListBox() {
      this.showSearch = false
      this.$refs.searchCompanies.instance.reset()
      this.taskForm.companyId = null
    },
    updateTaskSubject(e) {
      let taskType = ''
      if (e.selectedItem.value !== TaskTypesEnum.OTHER.value) {
        taskType = e.selectedItem.text
      }
      this.taskForm.subject = taskType
    },
    updateWorkingDayList(day) {
      this.workingDay = day
      this.showSearch = false
      this.taskForm.companyId = null
      this.$refs.searchCompanies.instance.reset()
      this.getWorkingDayCompanies()
    },
    customDisplayExpr(item) {
      return item ? `${item.departments} - ${item.fullName}` : ''
    },
    async getWorkingDayCompanies() {
      const response = await companyService.getWorkingDayCompanies(this.workingDay)
      this.companyList = response.map(el => ({ text: `${el.accountNo} : ${el.name}`, value: el.id, accountNo: el.accountNo }))
    },
    async getStoresByParentCompanyId(e) {
      this.stores = []
      const companyId = e.selectedItem.value
      this.taskForm.companyId = companyId
      const result = await commonEntityService.fetchStoresByParentCompany(companyId)
      this.stores = result.data.map(el => ({ value: el.id, text: el.name }))
    },
    getUsersList() {
      UserSearchFilter.setDefaultFilters()
      UserSearchFilter.companyId = this.userCompanyId
      UserSearchFilter.activated = true
      const filters = UserSearchFilter.getFilters()
      const pager = new Pager()
      userManagementService
        .getUserInfoBasicByQuery(filters, pager.staticPageable)
        .then(response => {
          const data = response.data
          this.currentCompanyUsers = data.content
        })
    },
    checkAssignedDays(day) {
      return this.workingDays.includes(day)
    },
    onStaffChanged(e) {
      this.selectedUser = e.selectedItem
    },
    async handleSubmit() {
      if (!this.taskForm.companyId) {
        Notify.error('Please select a company first.')
        return
      }
      const result = this.$refs.taskFormRef.instance.validate()
      if (result.isValid) {
        this.taskForm.assigner = this.userId

        await companyService.createTask(this.taskForm)
        this.$emit('emit-form-saved')
        this.closePopup()
      }
    },
    taskPopupHidden() {
    // Clear form when popup is closed
      this.$refs.searchCompanies.instance.reset()
      this.taskForm = { ...initialTaskForm }
    },
    closePopup() {
      this.showSearch = false
      this.taskPopup.hide()
    },
    async searchCompany(e) {
      this.stores = []
      const query = e.component.option('text')
      if (query === '' || query === undefined || query.length < 3) {
        return
      }
      const params = { name: query, type: 'customer' }
      await companyService.fetchAllMatchingNameAndType(params).then(response => {
        const data = []
        response.data.forEach(el => {
          data.push({
            companyNameWithAccount: `${el.accountNo} : ${el.name}`, name: el.name, accountNo: el.accountNo, value: el.id,
          })
        })
        this.customerCompanies = data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 10px;
}
.span-two {
  grid-column: span 2;
}
label {
  padding-left: 10px;
  font-size: 10px;
}
</style>
